<template>
  <locale-router-link
    :to="`articles/${item.id}`"
    tag="div"
    v-if="item"
    class="interesting__item"
  >
    <img
      :src="getImage(item)"
      alt="interesting image"
      class="interesting__img"
    />
    <div class="interesting__title-wrapper">
      <div class="interesting__item-title">
        {{ item.attributes.Header }} / <span>{{ item.attributes.Type }}</span
        >а
      </div>
    </div>
  </locale-router-link>
</template>

<script>
export default {
  name: "ArticlesTopCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getImage(item) {
      const urlImg = item?.attributes?.image?.data?.attributes?.url;
      if (urlImg) {
        return this.$helpers.getAbsolutePath(urlImg);
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
};
</script>

<style lang="scss" scoped></style>
